<template>
  <q-table
    class="q-pb-sm"
    flat
    bordered
    virtual-scroll
    style="height: 400px; border-color: #1976d2"
    :title="title"
    table-header-class="text-grey"
    title-class="text-body1 text-bold"
    :pagination.sync="pagination"
    :rows-per-page-options="[0]"
    :columns="columns"
    :data="data"
    hide-pagination
  >
    <template v-slot:body-cell-commission_full="props">
      <q-td style="width: 200px">
        <p class="text-caption q-mb-none text-right">
          <b>R$</b>
          {{ parseFloat(props.row.comission_full).toLocaleString('pt-br') }}
        </p>
        <div :class="`bg-blue-2 rounded`" style="width: 100%; height: 6px">
          <div
            :class="`bg-blue-${Math.round(props.row.percentage / 14) + 3} rounded`"
            :style="`width: ${props.row.percentage}%; height: 6px`"
          ></div>
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-percentage="props">
      <q-td class="">
        <div
          class="bg-blue-2 rounded flex justify-center items-center text-bold"
          :style="`height: 35px; width: 65px; margin: 0 auto; border: 1px solid #1976D2`"
        >
          {{ props.row.percentage }} %
        </div>
      </q-td>
    </template>
  </q-table>
</template>

<script>
export default {
  name: 'SummaryTable',
  props: {
    columns: {
      type: Array,
      require: true
    },
    data: {
      type: Array,
      require: true
    },
    type: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: false
    }
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 0
      }
    };
  }
};
</script>

<style>
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #929292;
}
</style>
