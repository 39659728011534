<template>
  <q-page padding>
    <q-toolbar class="q-px-md q-mb-md">
      <div class="q-mr-auto">
        <q-toolbar-title class="text-weight-medium text-h5">Dashboard </q-toolbar-title>

        <q-breadcrumbs class="">
          <q-select
            v-model="selectedVision"
            map-options
            :options="[
              { label: 'Visão Geral', value: 'general' },
              { label: 'Detalhes da Campanha', value: 'campaign' }
            ]"
            emit-value
            style="width: 180px"
            dense
            class="text-caption"
            hide-bottom-space
            borderless
          />
        </q-breadcrumbs>
      </div>

      <q-checkbox
        label="Ignorar campanhas de teste"
        true-value="1"
        v-if="selectedVision == 'general'"
        false-value="0"
        v-model="ignoreTestCampaigns"
        class="q-mr-sm"
      />
      <date-input
        buttons
        class="q-mr-sm"
        dense
        outlined
        label="Período"
        :v-model="period"
        @update="
          (val) => {
            period = val;
          }
        "
        today-btn
        range
      />

      <q-btn-dropdown
        no-caps
        outline
        class="q-mr-sm"
        content-style="width: 380px"
        :menu-offset="[0, 10]"
        v-if="selectedVision == 'general'"
        label="Filtros"
      >
        <div style="" class="row">
          <div
            class="col-12 justify-between items-center row"
            v-for="(filter, index) in Object.values(filters)"
            :key="index"
          >
            <div class="items-center flex q-pa-md" rounded>{{ filter.label }}</div>
            <q-btn
              color="white"
              text-color="blue"
              @click="filter.editing = !filter.editing"
              unelevated
              :icon="filter.editing ? 'mdi-arrow-collapse-left' : 'mdi-arrow-collapse-down'"
              style="font-size: 12px"
              round
              no-caps
            />
            <transition name="fade">
              <div class="col-12 q-pa-md" style="width: full" v-if="filter.editing">
                <q-select
                  v-model="filter.value"
                  :options="filter.name == 'statuses' ? filter.options : filter.optionsFiltered"
                  map-options
                  emit-value
                  :label="filter.label"
                  :option-label="filter.option_label"
                  :option-value="filter.option_value"
                  outlined
                  clearable
                  use-input
                  @filter="(val, update) => update(selectFilter(val, index, filter.option_label))"
                />
              </div>
            </transition>
          </div>
        </div>
        <div class="flex justify-center q-pa-md q-mt-sm">
          <q-btn label="Remover Filtros" unelevated color="black" style="width: 160px" @click="resetFilters" />
        </div>
      </q-btn-dropdown>

      <q-select
        v-if="selectedVision == 'campaign'"
        v-model="filters.campaign.value"
        outlined
        clearable
        dense
        class="q-mr-sm relative-position"
        @clear="(val) => (filters.campaign.value = '')"
        @filter="
          (val, update) => {
            filters.campaign.value = '';
            update(selectFilter(val, 3, filters.campaign.option_label));
          }
        "
        use-input
        map-options
        :label="filters.campaign.label"
        :display-value="
          filters.campaign.value == ''
            ? ''
            : filters.campaign.value.name.length > 20
            ? filters.campaign.value.name.slice(0, 14) + '...'
            : filters.campaign.value.name
        "
        input-class="absolute"
        style="width: 240px"
        :options="filters.campaign.optionsFiltered"
        :option-label="filters.campaign.option_label"
        :option-value="filters.campaign.option_value"
      >
      </q-select>

      <q-select
        v-if="selectedVision == 'campaign'"
        v-model="filters.status.value"
        outlined
        clearable
        class="q-mr-md"
        style="width: 180px"
        dense
        map-options
        emit-value
        :label="filters.status.label"
        :options="filters.status.options"
        :option-label="filters.status.option_label"
        :option-value="filters.status.option_value"
      />

      <q-btn
        color="primary"
        padding="7px 7px"
        unelevated
        icon="search"
        dense
        class=""
        @click="retrieveDashboardResume"
      />
    </q-toolbar>

    <div class="q-px-md" v-if="selectedVision == 'general'">
      <div class="row justify-around">
        <div class="row q-col-gutter-x-md">
          <div class="col-3" v-for="(data, index) in summariesData" :key="index">
            <SummaryCard :selectedVision="selectedVision" :data="data" :key="index" />
          </div>
        </div>
      </div>

      <div class="row q-col-gutter-x-md q-mt-lg">
        <div class="col">
          <SummaryTable
            :data="commissionPerCampaign"
            :title="'Full por Campanha'"
            :columns="commissionTableColumns"
            :type="'campaign'"
          />
        </div>
        <div class="col">
          <SummaryTable
            :data="commissionPerChannel"
            :title="'Full por Canal'"
            :columns="commissionTableColumns"
            :type="'channel'"
          />
        </div>
      </div>
      <div class="row q-col-gutter-x-md q-mt-lg">
        <div class="col-4">
          <SummaryBarChart :data="commissionPerMonthData" />
        </div>
        <div class="col-4">
          <SummaryPizzaChart
            :title="'Status das Conversões'"
            :id="'commission-full-by-status'"
            :data="commissionPerStautsData"
          />
        </div>
        <div class="col-4">
          <SummaryLineChart
            :data="commissionPerDay"
            :title="'Comissão Full Por Dia'"
            :dataProperty="'comission_full'"
          />
        </div>
      </div>
    </div>

    <div class="q-px-md" v-if="selectedVision == 'campaign'">
      <div class="row no-wrap q-gutter-md">
        <SummaryCard
          v-for="(data, index) in campaignSummaries"
          :selectedVision="selectedVision"
          :data="data"
          :key="index + data.title"
        />
      </div>

      <div class="row q-col-gutter-x-md q-mt-lg">
        <div class="col-6">
          <SummaryTable class="q-mb-lg" :data="campaignChannelResults" :columns="channelTableColumns" />

          <div class="row q-col-gutter-x-sm">
            <div class="col-6">
              <SummaryLineChart
                :id="'commission-full-daily-value-chart-campaign'"
                :title="'Conversões por dia'"
                :dataProperty="'transactions'"
                :data="campaignCommissionByDay"
              />
            </div>
            <div class="col-6">
              <SummaryPizzaChart
                :id="'commission-by-status-chart-campaign'"
                :data="campaignCommissionPerStatus"
                :title="'Aprovação'"
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- <SummaryBarChart :data="campaignTransactionsByMonth" /> -->
          <SummaryDoubleBarChart :data="campaignTransactionsByMonth" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import SummaryCard from '@/components/smartDashboard/SummaryCard.vue';
import SummaryTable from '@/components/smartDashboard/SummaryTable.vue';
import SummaryBarChart from '@/components/smartDashboard/SummaryBarChart.vue';
import SummaryDoubleBarChart from '@/components/smartDashboard/SummaryDoubleBarChart.vue';
import SummaryPizzaChart from '@/components/smartDashboard/SummaryPizzaChart.vue';
import SummaryLineChart from '@/components/smartDashboard/SummaryLineChart.vue';
import DateInput from '@/widgets/inputsGlobal/DateInput.vue';
import DashboardResumeService from '@/services/DashboardResume.js';
import AffiliateService from '@/services/AffiliateService.js';
import AdvertiserService from '@/services/AdvertiserService.js';
import UserService from '@/services/UserService.js';

export default {
  name: 'SmartDashboard',
  components: {
    SummaryCard,
    DateInput,
    SummaryTable,
    SummaryBarChart,
    SummaryPizzaChart,
    SummaryLineChart,
    SummaryDoubleBarChart
  },
  mixins: [DashboardResumeService, AffiliateService, AdvertiserService, UserService],
  data() {
    return {
      commissionPerCampaign: [],
      selectedVision: 'general',
      commissionPerChannel: [],
      commissionPerMonthData: [],
      commissionPerStautsData: [],
      commissionPerDay: [],
      campaignCommissionByDay: [],
      campaignCommissionPerStatus: [],
      campaignTransactionsByMonth: [],
      campaignChannelResults: [],
      commissionTableColumns: [
        {
          name: 'name',
          label: 'Nome',
          field: 'name',
          align: 'center',
          classes: 'text-blue text-bold ellipsis',
          style: 'max-width: 200px'
        },
        { name: 'commission_full', label: 'Valor', field: 'comission_full', align: 'center' },
        { name: 'percentage', label: '%', field: 'percentage', align: 'center' }
      ],

      channelTableColumns: [
        {
          name: 'channel_name',
          label: 'Canal',
          field: 'channel_name',
          align: 'center'
        },
        {
          name: 'transactions',
          label: 'Conversão',
          field: 'transactions',
          align: 'center'
        },
        {
          name: 'average_ticket',
          label: 'Ticket Médio',
          field: 'average_ticket',
          align: 'center',
          //eslint-disable-next-line
          format: (val, row) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'comission_full',
          label: 'Comissão Full',
          field: 'comission_full',
          align: 'center',
          //eslint-disable-next-line
          format: (val, row) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'cost',
          label: 'Custo',
          field: 'cost',
          align: 'center',
          //eslint-disable-next-line
          format: (val, row) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'margin',
          label: 'Margem',
          field: 'margin',
          align: 'center',
          //eslint-disable-next-line
          format: (val, row) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'share_full',
          label: '% Share full',
          field: 'share_full',
          align: 'center',
          //eslint-disable-next-line
          format: (val, row) => val + ' %'
        },
        {
          name: 'share_margin',
          label: '% Share margem',
          field: 'share_margin',
          align: 'center',
          //eslint-disable-next-line
          format: (val, row) => val + ' %'
        }
      ],
      period: '',
      ignoreTestCampaigns: '1',
      campaignSummaries: {
        comission_full: { title: 'Comissão Full', value: '', color: '#747474', icon: 'mdi-trending-up', type: 'money' },
        cost: { title: 'Custo', value: '', color: '#747474', icon: 'mdi-cash', type: 'money' },
        margin: { title: 'Margem', value: '', color: '#747474', icon: 'mdi-chart-line', type: 'money' },
        margin_percentage: {
          title: '% Margem',
          value: '',
          color: '#747474',
          icon: 'mdi-percent-outline',
          type: 'percent'
        },
        transactions: { title: 'Conversões', value: '', color: '#FE7FEB', type: 'quantity' },
        affiliates: { title: 'Afiliados', value: '', color: '#FE7FEB', type: 'quantity' },
        average_ticket: { title: 'Ticket Médio', value: '', color: '#FE7FEB' }
      },
      summariesData: {
        comission_full: { title: 'Comissão Full', value: '', color: '#17ccf3', icon: 'mdi-trending-up', type: 'money' },
        cost: { title: 'Custo', value: '', color: '#f8aa4c', icon: 'mdi-cash', type: 'money' },
        margin: { title: 'Margem', value: '', color: '#FBCB42', icon: 'mdi-chart-line', type: 'money' },
        margin_percent: {
          title: '% Margem',
          value: '',
          color: '#766BFB',
          icon: 'mdi-percent-outline',
          type: 'percent'
        }
      },
      filters: {
        status: {
          label: 'Status',
          name: 'statuses',
          value: '',
          editing: false,
          options: [
            { label: 'Aprovado', value: 2 },
            { label: 'Pendente', value: 1 },
            { label: 'Recusado', value: 0 }
          ]
        },
        campaign_manager: {
          label: 'Gerente de campanha',
          name: 'campaign_manager',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'user_id'
        },
        affiliate_manager: {
          label: 'Gerente de afiliados',
          name: 'affiliate_manager',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'user_id'
        },
        campaign: {
          label: 'Campanha',
          name: 'campaigns',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'id'
        },
        channel: {
          label: 'Canal',
          name: 'channels',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'id'
        },
        segmentation: {
          label: 'Segmentação de campanha',
          name: 'segmentation',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'description',
          option_value: 'id'
        }
      }
    };
  },
  methods: {
    async retrieveDashboardResume() {
      if (this.selectedVision == 'general') {
        this.getGeneralDashboardResume();
      } else if (this.selectedVision == 'campaign') {
        if (this.filters.campaign.value) {
          this.getCampaignDashboardResume();
          return;
        }

        this.errorNotify('O filtro campanha é obrigatório.');
      }
    },

    periodString() {
      var [startDate, endDate] = this.period.split(' - ');
      return `start_date%3D${this.formatToEn(startDate)}%26end_date%3D${this.formatToEn(endDate)}%26`;
    },

    async getGeneralDashboardResume() {
      try {
        this.onLoading(true);
        var filterString = '%3F';
        var filterChartsString = '%3F';
        filterString += this.periodString();
        Object.values(this.filters).forEach((filter) => {
          if (filter.value !== '' && filter.value !== null) {
            filterString += filter.name + '%3D' + filter.value + '%26';
            filterChartsString += filter.name + '%3D' + filter.value + '%26';
          }
        });
        filterString += 'ignore_test%3D' + this.ignoreTestCampaigns;
        filterChartsString += 'ignore_test%3D' + this.ignoreTestCampaigns;

        const data = await Promise.all([
          this.getSmartDashboardResume(filterString),
          this.getCampaignCommissionFull(filterString),
          this.getChannelCommissionFull(filterString),
          this.getCommissionPerMonth(filterChartsString),
          this.getCommissionByStatus(filterString),
          this.getCommissionFullDaily(filterChartsString)
        ]);

        for (const key in data[0].data) {
          this.summariesData[key].value = data[0].data[key];
        }

        this.commissionPerCampaign = data[1].data;
        this.commissionPerChannel = data[2].data;
        this.commissionPerMonthData = data[3].data;
        this.commissionPerStautsData = data[4].data.sort((a, b) => a.status - b.status);
        this.commissionPerDay = data[5].data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCampaignDashboardResume() {
      try {
        this.onLoading(true);
        var filterString = '%3F';
        filterString += 'campaign_id' + '%3D' + this.filters.campaign.value.id;

        if (this.filters.status.value) {
          filterString += '%26status' + '%3D' + this.filters.status.value;
        }

        const [
          dashboardResume,
          campaignChannelTable,
          dailyCommisionData,
          campaignCommissionByStatus,
          campaignTransactionsByMonth
        ] = await Promise.all([
          await this.getCampaignVisionSmartDashboardResume(filterString + '%26' + this.periodString()),
          await this.getCampaignChannelResume(filterString + '%26' + this.periodString()),
          await this.getCampaignCommissionByDay(filterString),
          await this.getCampaignCommissionByStatus(filterString + '%26' + this.periodString()),
          await this.getCampaignTransactionsByMonth(filterString)
        ]);

        Object.keys(this.campaignSummaries).forEach((key) => {
          this.campaignSummaries[key].value = dashboardResume.data[key];
        });

        this.campaignChannelResults = campaignChannelTable.data;
        this.campaignCommissionByDay = dailyCommisionData.data;
        this.campaignCommissionPerStatus = campaignCommissionByStatus.data;
        this.campaignTransactionsByMonth = campaignTransactionsByMonth.data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    selectFilter(val, index, prop = null) {
      const needle = val.toLocaleLowerCase();
      const filters = Object.values(this.filters);
      filters[index].optionsFiltered = needle
        ? filters[index].options.filter((v) =>
            prop ? v[prop].toLocaleLowerCase().includes(needle) : v.toLocaleLowerCase().includes(needle)
          )
        : filters[index].options;
    },

    async retrieveSelectOptions() {
      try {
        this.onLoading(true);
        const options = await Promise.all([
          this.getManagersOptions(),
          this.getAffiliateManagerOptions(),
          this.getAllCampaigns(),
          this.getAllSites(),
          this.getSegmentationsList()
        ]);

        this.filters['campaign_manager'].options = this.filters['campaign_manager'].optionsFiltered = options[0].data;
        this.filters['affiliate_manager'].options = this.filters['affiliate_manager'].optionsFiltered = options[1].data;
        this.filters['campaign'].options = this.filters['campaign'].optionsFiltered = options[2].data;
        this.filters['channel'].options = this.filters['channel'].optionsFiltered = options[3].data;
        this.filters['segmentation'].options = this.filters['segmentation'].optionsFiltered = options[4].data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    resetFilters() {
      Object.values(this.filters).forEach((filter) => {
        filter.value = '';
      });
    }
  },

  watch: {
    selectedVision() {
      this.resetFilters();
      if (this.selectedVision == 'general') this.retrieveDashboardResume();
      else Object.values(this.campaignSummaries).map((val) => (val.value = ''));
    }
  },

  created() {
    this.period = `${this.$moment().subtract(7, 'days').format('L')} - ${this.$moment().format('L')}`;
    this.retrieveDashboardResume();
    this.retrieveSelectOptions();
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fadeVision-enter-active,
.fadeVision-leave-active {
  transition: opacity 1s;
}
.fadeVision-enter, .fadeVision-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
