<template>
  <q-card flat class="q-pa-md" bordered style="border-color: #0086e6">
    <p class="text-body1 text-bold">{{ title }}</p>
    <canvas :id="id"></canvas>
  </q-card>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'SummaryPizzaChart',

  props: {
    data: { require: true, type: Array, default: () => [{ status_description: '', quantity: 0 }] },
    id: { require: true, type: String, default: 'pizza-chart' },
    title: { require: true, type: String, default: '' }
  },

  data() {
    return {
      chart: null,
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    };
  },

  watch: {
    data: function () {
      // console.log(this.data);
      this.mountChart();
    }
  },
  methods: {
    async mountChart() {
      var labelColors = {
        recusado: 'rgba(207, 73, 70, 1)',
        pendente: 'rgba(248, 237, 93, 1)',
        aprovado: 'rgba(125, 203, 91, 1)'
      };
      if (!this.chart) {
        var ctx = document.getElementById(this.id);
        const labels = this.data.map((val) => val.status_description + ' | ' + val.quantity);
        const data = this.data.map((val) => val.percentage);
        this.chart = new Chart(ctx, {
          type: 'pie',

          data: {
            labels: labels,
            datasets: [
              {
                label: 'Captações',
                data: data,
                backgroundColor: this.data.map((val) => labelColors[val.status_description.toLowerCase()]),
                borderColor: 'rgba(20, 50, 155, 1)',
                borderWidth: 0.5
              }
            ]
          },
          options: {
            tooltips: {
              callbacks: {
                // eslint-disable-next-line
                label: function (item, chart) {
                  var label = this._data.labels[item['index']].split(' | ');
                  return label[0] + ':  ' + chart['datasets'][0]['data'][item['index']] + ' % (' + label[1] + ')';
                }
              }
            },
            legend: {
              position: 'bottom'
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    // eslint-disable-next-line
                    callback: function (value, index, values) {
                      return ' ';
                    }
                  }
                }
              ]
            }
          }
        });
      } else {
        this.chart.data.labels = this.data.map((val) => val.status_description + ' | ' + val.quantity);
        this.chart.data.datasets.forEach((dataset) => {
          dataset.data = this.data.map((val) => val.percentage);
          dataset.backgroundColor = this.data.map((val) => labelColors[val.status_description.toLowerCase()]);
        });
        this.chart.update();
      }
    }
  }
};
</script>

<style></style>
