<template>
  <q-card flat class="q-pa-md" bordered style="border-color: #0086e6">
    <p class="text-body1 text-bold">{{ title }}</p>
    <canvas :id="id"></canvas>
  </q-card>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'SummaryLineChart',

  props: {
    data: { require: true, type: Array, default: () => [{ status_description: '', quantity: 0 }] },
    id: { require: true, type: String, default: 'commission-full-daily-value-chart' },
    title: { require: true, type: String, default: '' },
    dataProperty: { require: true, type: String, default: 'comission_full' }
  },

  data() {
    return {
      chart: null
    };
  },

  watch: {
    data: function () {
      this.mountChart();
    }
  },
  methods: {
    async mountChart() {
      if (!this.chart) {
        var ctx = document.getElementById(this.id);
        const data = this.data.map((val) => val[this.dataProperty]);
        const labels = this.data.map((val) => val.day.slice(0, 5));
        const typeLegend = this.dataProperty == 'comission_full' ? 'Comissão Full' : 'Conversões';
        this.chart = new Chart(ctx, {
          type: 'line',

          data: {
            labels: labels,
            datasets: [
              {
                label: typeLegend,
                data: data,
                backgroundColor: 'rgba(255, 204, 247, 1)',
                borderColor: 'rgba(20, 50, 155, 1)',
                borderWidth: 1
              }
            ]
          },
          options: {
            tooltips: {
              callbacks: {
                //eslint-disable-next-line
                label: function (item, chart) {
                  return chart.datasets[0].label === 'Conversões'
                    ? 'Conversões: ' + item.yLabel
                    : 'Comissão: R$ ' + item.yLabel.toLocaleString('pt-br');
                }
              }
            },
            legend: {
              position: 'bottom'
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    // eslint-disable-next-line
                    callback: function (value, index, values) {
                      return typeLegend == 'Conversões' ? value : 'R$ ' + value.toLocaleString('pt-br');
                    },
                    fontSize: 10,
                    maxTicksLimit: typeLegend == 'Conversões' ? 5 : 10
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    fontSize: 10
                  }
                }
              ]
            }
          }
        });
      } else {
        this.chart.data.labels = this.data.map((val) => val.day.slice(0, 5));
        this.chart.data.datasets.forEach((dataset) => {
          dataset.data = this.data.map((val) => val[this.dataProperty]);
        });
        this.chart.update();
      }
    }
  }
};
</script>

<style></style>
