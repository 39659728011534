import { render, staticRenderFns } from "./SummaryTable.vue?vue&type=template&id=a5a3f75a"
import script from "./SummaryTable.vue?vue&type=script&lang=js"
export * from "./SummaryTable.vue?vue&type=script&lang=js"
import style0 from "./SummaryTable.vue?vue&type=style&index=0&id=a5a3f75a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTd});
