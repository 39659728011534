<template>
  <q-card flat class="q-pa-md" bordered style="border-color: #0086e6">
    <p class="text-body1 text-bold">Performance mês a mês</p>
    <div class="" style="position: relative; min-height: 326px; max-height: auto">
      <canvas id="commission-full-double-bar-chart"></canvas>
    </div>
  </q-card>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'SummaryBarChart',

  props: {
    data: { require: true, type: Array }
  },

  data() {
    return {
      chart: null,
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    };
  },
  watch: {
    data: function () {
      // console.log(this.data);
      if (this.data.length != 0) {
        this.mountChart();
      }
    }
  },
  methods: {
    async mountChart() {
      if (!this.chart) {
        var ctx = document.getElementById('commission-full-double-bar-chart');
        const labels = this.data.map((val) => this.months[Number(val.period.split('/')[0]) - 1]);
        const data = this.data.map((val) => val.comission_full);
        const marginData = this.data.map((val) => val.margin);

        const commissionFullDataSet = {
          label: 'Comissão Full',
          data: data,
          backgroundColor: 'rgba(125, 46, 71, 1)',
          borderColor: 'rgba(0, 200, 255, 1)',
          borderWidth: 0
        };

        const marginDataSet = {
          label: 'Margem',
          data: marginData,
          backgroundColor: 'rgba(184, 49, 116, 1)',
          borderColor: 'rgba(160, 40, 40, 1)',
          borderWidth: 0
        };

        this.chart = new Chart(ctx, {
          type: 'bar',

          data: {
            labels: labels,
            datasets: [marginDataSet, commissionFullDataSet]
          },
          options: {
            tooltips: {
              callbacks: {
                label: function (item, chart) {
                  return ' R$ ' + item.yLabel.toLocaleString('pt-br') + ' ' + chart.datasets[item.datasetIndex].label;
                }
              }
            },

            legend: {
              position: 'bottom'
            },

            elements: {
              bar: {
                borderRadius: 5
              }
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    // eslint-disable-next-line
                    callback: function (value, index, values) {
                      return (value / 1000).toLocaleString('pt-br') + 'k';
                    },

                    fontSize: 10
                  }
                }
              ],
              xAxes: [
                {
                  stacked: true,
                  ticks: {
                    fontSize: 10
                  }
                }
              ]
            }
          }
        });
      } else {
        this.chart.data.datasets.forEach((dataset) => {
          if (this.dataset.label === 'Comissão Full') {
            dataset.data = this.data.map((val) => val.comission_full);
          } else {
            dataset.data = this.data.map((val) => val.margin);
          }
        });
        this.chart.update();
      }
    }
  }
};
</script>

<style></style>
